import { useEffect, useState } from 'react';
import api from '../services/api';
import {EventResults} from '../utils/commonTypes';

const TableOfResults = ({ searchQuery, event_id }: { searchQuery: string; event_id: number }) => {
    const [results, setResults] = useState<EventResults[]>([]);
    const [selectedPhase, setSelectedPhase] = useState<string>('1');

    const getMedalColor = (index: number) => {
        switch (index) {
            case 0: return 'text-amber-500';
            case 1: return 'text-neutral-300';
            case 2: return 'text-yellow-800';
            default: return 'text-white';
        }
    };

    useEffect(() => {
        api.getEventResults(event_id, Number(selectedPhase))
            .then((response) => {
                const results = response.data.data as unknown as EventResults[];

                setResults(results);
            })
            .catch((error) => {
                console.error('Error fetching results:', error);
            });
    }, [event_id, selectedPhase]);

    const filteredData = results.filter((result) =>
        result.driver_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        result.driver_surname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        result.number.toLowerCase().includes(searchQuery) ||
        result.total_time.toString().includes(searchQuery.toLowerCase()) ||
        result.points.toString().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="overflow-x-auto">
            <div className='flex justify-center w-full'>
                <select
                    name="phase"
                    id="phase"
                    value={selectedPhase}
                    onChange={(e) => setSelectedPhase(e.target.value)}
                    className='custom-select rounded-full border border-red-600 pl-3 pr-7 py-1 mb-5 focus:outline-none focus:border focus:border-red-600 bg-white text-l font-normal'
                >
                    <option value="1">Trénink</option>
                    <option value="2">Kvalifikace</option>
                    <option value="3">Závod</option>
                </select>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full table-auto border-separate font-body" style={{ borderSpacing: '0 3px' }}>
                    <thead className="bg-red-600">
                        <tr>
                            <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider rounded-tl-lg">#</th>
                            <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider">Jméno</th>
                            <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider">Příjmení</th>
                            <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider">Startovní číslo</th>
                            <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider">Čas</th>
                            <th className="px-6 py-4 text-left text-xs font-medium text-white uppercase tracking-wider rounded-tr-lg">Body</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-gray-200">
                        {filteredData.length === 0 ? (
                            <tr>
                                <td colSpan={6} className="text-center">Nejsou dostupné žádné výsledky</td>
                            </tr>
                        ) : (
                            filteredData.map((result, index) => (
                                <tr key={index} className='bg-zinc-800 text-white'>
                                    <td className={`px-6 py-2 whitespace-nowrap font-bold ${getMedalColor(index)}`}>{index + 1}</td>
                                    <td className="px-6 py-2 whitespace-nowrap">{result.driver_name}</td>
                                    <td className="px-6 py-2 whitespace-nowrap">{result.driver_surname}</td>
                                    <td className="px-6 py-2 whitespace-nowrap">{result.number}</td>
                                    <td className="px-6 py-2 whitespace-nowrap">{result.total_time}</td>
                                    <td className="px-6 py-2 whitespace-nowrap">{result.points}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableOfResults;